import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    BehaviorSubject,
    filter,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';
import { Chat, Contact, Profile } from './chat.types';
import { AIAssistantService, ChatMessage } from 'app/ai-api';
import { UserService } from 'app/core/user/user.service';
import { AlertService } from 'app/core/alert/alert.service';

@Injectable({ providedIn: 'root' })
export class ChatService {
    private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject(null);
    private _contact: BehaviorSubject<Contact> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
    private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private aiService: AIAssistantService,
        private userService: UserService,
        private alertService: AlertService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat> {
        return this._chat.asObservable();
    }

    /**
     * Getter for chats
     */
    get chats$(): Observable<Chat[]> {
        return this._chats.asObservable();
    }

    /**
     * Getter for contact
     */
    get contact$(): Observable<Contact> {
        return this._contact.asObservable();
    }

    /**
     * Getter for contacts
     */
    get contacts$(): Observable<Contact[]> {
        return this._contacts.asObservable();
    }

    /**
     * Getter for profile
     */
    get profile$(): Observable<Profile> {
        return this._profile.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chats
     */
    getChats(): Observable<any> {
        return this.aiService.getUserChats(this.userService.user.id).pipe(
            tap((response: Chat[]) => {
                this._chats.next(response);
            })
        );
    }

    /**
     * Get contact
     *
     * @param id
     */
    getContact(id: string): Observable<any> {
        return this._httpClient.get<Contact>('api/apps/chat/contacts', { params: { id } }).pipe(
            tap((response: Contact) => {
                this._contact.next(response);
            })
        );
    }

    /**
     * Get contacts
     */
    getContacts(): Observable<any> {
        return this._httpClient.get<Contact[]>('api/apps/chat/contacts').pipe(
            tap((response: Contact[]) => {
                const aiContact: Contact = {
                    id: '1',
                    about: 'Your personal AI Assistant',
                    avatar: 'assets/images/avatars/no-avatar.png',
                    attachments: {
                        docs: [],
                        links: [],
                        media: [],
                    },
                    details: {},
                    name: 'AI Assistant',
                };
                this._contacts.next([aiContact]);
            })
        );
    }

    /**
     * Get profile
     */
    getProfile(): Observable<any> {
        return this._httpClient.get<Profile>('api/apps/chat/profile').pipe(
            tap((response: Profile) => {
                const currentUser = this.userService.user;
                const profile: Profile = {
                    email: currentUser.email,
                    name: currentUser.firstname + ' ' + currentUser.lastname,
                    id: currentUser.id,
                    avatar: 'assets/images/avatars/no-avatar.png',
                };
                this._profile.next(profile);
            })
        );
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<any> {
        return this.aiService.getChatById(id).pipe(
            map(chat => {
                // Update the chat
                this._chat.next(chat);

                // Return the chat
                return chat;
            }),
            switchMap(chat => {
                if (!chat) {
                    return throwError('Could not found chat with id of ' + id + '!');
                }

                return of(chat);
            })
        );
    }
    /**
     * Update chat
     *
     * @param id
     * @param chat
     */
    updateChat(id: string, chat: Chat): Observable<Chat> {
        return this.chats$.pipe(
            take(1),
            map(chats => {
                // Find the index of the updated chat
                const index = chats.findIndex(item => item.id === id);

                // Update the chat
                if (index !== -1) {
                    chats[index] = chat; // Directly update the chat with the provided 'chat' object
                }

                // Update the chats
                this._chats.next(chats);
                this._chat.next(chat);

                // Return the updated chat
                return chat;
            }),
            switchMap(updatedChat =>
                this.chat$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {
                        // Update the chat if it's selected
                        this._chat.next(updatedChat);
                        return updatedChat;
                    })
                )
            )
        );
    }

    errorOccured(): any {
        this.alertService.errorAlert('chat', 'serviceError');
    }

    /**
     * Reset the selected chat
     */
    resetChat(): void {
        this._chat.next(null);
    }
}
