import { Inject, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ChatMessage } from 'app/ai-api';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { FrontendAppConfiguration } from 'app/services/configuration.services';
import { Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { EmployeeService } from '../employees/employee.service';
import { ChatService } from './chat.service';
import { Chat } from './chat.types';

@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    private socket$: WebSocketSubject<any>;
    private chatService: ChatService = inject(ChatService);
    isTyping: WritableSignal<boolean> = signal(false);

    constructor(
        private employeeService: EmployeeService,
        @Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration
    ) {
        // this.connect();
    }

    public connect(): void {
        // Connect to the WebSocket server
        const token = sessionStorage.getItem('access_token');
        this.socket$ = webSocket(this.appConfig.nodeWSS + token);

        // Log messages from the server
        this.socket$.subscribe(
            async (chat: Chat) => {
                this.isTyping.set(false);
                this.chatService.updateChat(chat.id, chat).subscribe();
            },
            err => {
                console.error('WebSocket error:', err);
                this.isTyping.set(false);
                this.chatService.errorOccured();
            },
            () => console.warn('WebSocket connection closed')
        );
    }

    // Send a message to the server
    async sendMessage(message: ChatMessage): Promise<void> {
        // TODO get currently logged in user's employee data
        message.meta = {
            user_role: 'mm' ?? this.employeeService.selectedEmployee.roles[0],
        };
        this.socket$.next(message);
        let chat: Chat = await this.chatService.getChatById(message.chatId).toPromise();
        chat.messages.push(message);
        this.isTyping.set(true);
        // this.chatService.updateChat(message.chatId, chat).toPromise();
    }

    // Close the WebSocket connection
    closeConnection(): void {
        this.socket$.complete();
    }

    // Observable to handle responses
    get messages$(): Observable<any> {
        return this.socket$.asObservable();
    }
}
